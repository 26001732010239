.games-list-section {
  padding: 0 20px;

  .game-list-inner {
    max-width: 1396px;
    margin: auto;
  }

  h2 {
    margin: 0 0 24px;
    line-height: 116.667%;
  }

  .view-all {
    margin: 40px auto;

    @media (max-width: 600px) {
      width: calc(100% - 20px);
      justify-content: center;
      margin: 24px 0 40px;
    }
  }

  .games-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      gap: 12px;
    }
    .game-item {
      img:hover {
        filter: brightness(1.2);
      }
    }
  }

  .games-list-actions {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin: 24px 0;
    display: none;

    @media (max-width: 600px) {
      display: flex;
    }

    .show-more {
      width: 100%;
    }
  }

  &.top-games {

    @media (max-width: 600px) {
      padding-right: 0;

      .games-list-wrapper {
        overflow-y: hidden;
        padding-right: 26px;
  
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .games-list {
        width: calc(100vw * 3 - 138px);
        grid-template-columns: calc(100vw - 54px) calc(100vw - 54px) calc(100vw - 54px);
      }

    }

  }

}
