.download-actions {

  .dl-btn.btn-primary {

    svg {
      width: 24px;
      height: 24px;
    }
  }

}

.multi-downloads-menu {
  position: relative;

  &.is-open {

    .arrow-icon {
      transform: rotate(180deg);
    }
  
  }

  &:not(.is-open) {

    // .main-multi-btn .multi-options-btn:not(.icon) svg {
    //   transform: rotate(0deg);
    // }

    .multi-options-list {
      opacity: 0;
      pointer-events: none;
    }
  }

  .main-multi-btn {
    background-color: var(--ow-red);
    cursor: pointer;
    display: flex;
    align-items: center; 

    // &:focus,
    // &:active {
    //   background-color: var(--ow-red);
    //   color: var(--white);
    // }

    a {
      font-family: var(--font-montserrat);
      font-size: 16px;
      white-space: nowrap;
      font-weight: var(--font-weight-medium, 500);
      color: var(--white);
      text-transform: uppercase;
      display: flex;
      gap: 8px;
      align-items: center;
      height: 56px;
      padding: 0 24px;
      text-align: left;

        @media (any-hover: hover) {
          &:hover {
            background-color: var(--ow-red-hover);
          }
        }
    }

    svg  {
      width: 20px;
      height: 20px;
      color: var(--white);

      &:not(.icon) {
        width: 24px;
        height: 24px;
      }
    }

    .multi-options-btn {
      background-color: transparent;
      width: 40px;
      height: 56px;
      padding-top: 4px;
      position: relative;

      &::before {
        content: '';
        height: 24px;
        width: 1px;
        background-color: var(--color-icon-primary);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      @media (any-hover: hover) {
        &:hover {
          // background-color: var(--color-surface-on-surface);
          background-color: var(--ow-red-hover);
        }
      }

    }
  }

  .multi-options-list {
    background-color: var(--color-surface-tertiary);
    display: flex;
    width: 100%;
    padding: var(--space-200) 0px;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    z-index: 10;
    transition: opacity 150ms ease;
    opacity: 1;

    .option-item {
      width: 100%;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 16px;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          left: 0;
          bottom: -8px;
          margin: auto;
          width: calc(100% - 32px);
          height: 1px;
          background-color: var(--color-border-on-surface);
        }
      }

      a {
        display: flex;
        padding: 8px 16px;
        align-items: center;
        gap: 12px;
        width: 100%;
        color: var(--color-text-primary);
        font-family: var(--font-montserrat);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;

        svg {
          width: 24px;
          height: 24px;
          flex: none;
        }

        &:hover {
          background-color: var(--color-surface-on-surface);
        }
      }
    }
  }

}

.app-info-sticky-header {

  &.floats {

    .app-info-sticky-header-inner {

      .app-download-area {
        display: flex;
        flex-direction: row-reverse;
        gap: 16px;
        align-items: center;

        .app-inventory-list {
          padding: 0;
        }

        .dl-btn {
          min-width: 208px;
        }
      }
    }
  }
}
