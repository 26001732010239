.app-creator-section {
  margin: 40px 0;

  @media (max-width: 600px) {
    margin: 24px 0;
  }

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 116.667%;
    margin-bottom: 24px;

    @media (max-width: 600px) {
      margin-bottom: 16px;
      font-size: 20px;
    }
  }

  .app-creator-content {
    display: flex;
    justify-content: space-between;
    background-color: var(--surface-secondary);
    position: relative;

    .creator-info {
      padding: 16px 24px 24px; 
      max-width: 650px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 40px;

      @media (max-width: 700px) {
        position: relative;
        z-index: 1;
        padding-top: 80px;
        gap: 20px;
      }

      h3 {
        font-family: var(--font-lato);
        font-size: 18px;
        line-height: 155.556%;
        margin-bottom: 8px;
      }

      p {
        color: var(--text-tertiary);
        max-width: 520px;
      }
    }

    .creator-image {
      position: relative;
      flex: none;

      @media (max-width: 960px) {
        width: 328px;
      }

      @media (max-width: 700px) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 131px;
      }

      img {
        height: 100%;
        object-fit: cover;

        @media (max-width: 700px) {
          width: 100%;
          object-position: top;
        }
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          var(--surface-secondary) 0%,
          rgba(28, 28, 28, 0) 108.82%
        );

        @media (max-width: 700px) {
          top: unset;
          bottom: -4px;
          background: linear-gradient(
            360deg,
            var(--surface-secondary) 40.08%,
            rgba(28, 28, 28, 0) 100%
          );
        }
      }
    }
  }
}
