.search-input {
  position: relative;

  input[type=search] {
    padding-left: 36px;
    padding-right: 48px;
    appearance: none;
    border: 1px solid;
    border-color: var(--surface-quaternary);

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration { display: none; }

    &:hover, &:focus {
      border-color: var(--border-secondary);
    }
  }

  &:focus-within {
    .clear-search {
      opacity: 1;
      right: 0;
      cursor: pointer;
      // pointer-events: all;
    }
  }

  > svg {
    width: 20px;
    height: 20px;
    color: var(--icons-secondary);
    position: absolute;
    left: 12px;
    top: 0;
    bottom: -2px;
    margin: auto;
    pointer-events: none;
  }

  .clear-search {
    color: var(--icons-secondary);
    position: absolute;
    right: -8px;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    // pointer-events: none;
    cursor: auto;
    padding: 0;
    background-color: transparent;
    width: 48px;
    height: 48px;
    z-index: 10;

    &:hover {
      color: var(--text-secondary);
    }

    svg {
      width: 24px;
      height: 24px;
      position: relative;
      bottom: -3px;
    }
  }
}
