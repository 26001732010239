body:has(.no-results-found) {
  .site-footer .back-to-top {
    display: none
  }
}

.search-page-layout {
  min-height: 100vh;

  @media (min-width: 1024px) {
    padding-top: 76px;
  }

  .sr-header {
    max-width: 1436px;
    margin: auto;
    padding: 0 20px;

    h1 {
      font-size: 44px;
      line-height: 56px;
      margin-bottom: 24px;
  
      @media (max-width: 1024px) {
        font-size: 24px;
        line-height: 28px;
      }
  
      span {
        font-weight: 700;
      }
    }
    
    .found {
      margin: 24px 0;
      color: var(--text-quaternary);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
  
      @media (max-width: 600px) {
        margin-bottom: 16px;
        font-size: 14px;
      }
    }
  }

  .no-results-found {
    max-width: 1396px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 20px;
    align-items: center;
    text-align: center;

    img {
      @media (max-width: 600px) {
        max-width: 320px;
        height: 200px;
        object-fit: cover;
      }
    }

    h2 {
      font-size: 32px;
      line-height: 40px;

      @media (max-width: 1024px) {
        font-size: 24px;
        line-height: 28px;
      }

      span {
        font-weight: 700;
      }
    }

    p {
      font-family: var(--font-montserrat);
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;

      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px
      }
    }
  }

  .start-search {
    max-width: 1396px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 20px;
    align-items: center;
    text-align: center;

    img {
      position: relative;
      left: -24px;

      @media (max-width: 600px) {
        max-width: 282px;
        height: 200px;
        object-fit: cover;
        left: -14px;
       }
    }

    h2 {
      font-size: 32px;
      line-height: 40px;

      @media (max-width: 1024px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

  }
}
