@mixin app-hover {
  .app-dl, .link-btn, .multi-tile-menu {
    opacity: 1;
    color: var(--white);
    pointer-events: all;
  }

  .info-card {
    bottom: 0px;
  }

  .card-header {
    padding-bottom: 8px;
    gap: 16px;

    .app-icon {
      width: 48px;
      height: 48px;
    }

    h3 {
      width: 164px;
      bottom: -4px;
    }

    .type {
      opacity: 0;
    }

    .star-rating {
      opacity: 1;
    }
  }

  .card-body p {
    opacity: 1;
  }
}

.apps-list-section {
  padding: 0 20px;

  .apps-list-inner {
    max-width: 1396px;
    margin: 0 auto 40px auto;
  }

  h2 {
    margin-bottom: 24px;

    @media (max-width: 1024px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  .apps-list-wrapper {
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .apps-list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    .app-item {
      width: 260px;
      position: relative;
      padding-bottom: 88px;
      overflow: hidden;

      .app-thumb {
        width: 260px;
      }

      .show-info-btn {
        position: absolute;
        right: 12px;
        bottom: 12px;
        z-index: 1;
        background-color: transparent;
        display: none;

        @media (max-width: 700px) {
          display: block;
        }

        .icon-expand {
          display: none;
        }
      }

      @media (any-hover: hover) {
        &:hover {
          @include app-hover;
        }
      }

      &.is-shown {
        @include app-hover;
      }

      a {
        display: block;

        &:not(.app-dl, .link-btn) {
          width: 100%;
        }

        &:hover, &:focus {
          color: inherit;
        }
      }

      .app-dl, .link-btn, .multi-tile-menu {
        position: absolute;
        right: 16px;
        bottom: 16px;
        z-index: 1;
        opacity: 0;
        width: calc(100% - 32px);
        text-align: center;
        transition: all 150ms ease-in;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 190%;
      }

      .multi-tile-menu {
        text-transform: unset;
        text-align: left;
      }

      .multi-dl {
        padding: 2px 24px 0 24px;
        width: 100%;
        justify-content: center;
        cursor: auto;
      }

      .tile-multi-dl-list {
        position: fixed;
        width: 228px;
      }

      .link-btn {
        display: none;

        @media (max-width: 1024px) {
          pointer-events: none;
          display: block;
        }
      }

      .app-dl, .multi-tile-menu {
        @media (max-width: 1024px) {
          display: none;
        }
      }

      .info-card {
        background-color: var(--color-surface-secondary);
        padding: 16px;
        position: absolute;
        bottom: -199px;
        width: 100%;
        height: 100%;
        z-index: 0;
        transition: all 300ms ease;
      }

      .card-header {
        display: flex;
        gap: 0;
        padding-bottom: 12px;
        transition: all 300ms ease;

        .app-icon {
          width: 0;
          height: 0;
          transition: all 300ms ease;
        }

        .title {
          display: flex;
          flex-direction: column;
          gap: 8px;
          position: relative;
        }

        h3 {
          font-size: 16px;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 236px;
          position: relative;
          transition: all 300ms ease;
        }

        .type {
          font-family: var(--font-montserrat);
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          color: var(--text-quaternary);
          opacity: 1;
          transition: opacity 300ms ease;
        }

        .star-rating {
          font-size: 20px;
          position: absolute;
          bottom: 12px;
          opacity: 0;
          transition: opacity 300ms ease;

          @media (max-width: 600px) {
            font-size: 16px;
          }
        }
      }

      .card-body {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        overflow: hidden;

        p {
          color: var(--text-tertiary);
          font-size: 16px;
          font-weight: 400;
          line-height: 24px; /* 142.857% */
          opacity: 0;
          transition: opacity 300ms ease;
        }
      }
    }
  }

  &:not(.list-type) {

    @media (max-width: 600px) {
      padding-right: 0;
    }

    .apps-list {

      @media (max-width: 600px) {
        flex-wrap: nowrap;
        width: fit-content;
        padding-right: 20px;
        gap: 12px;

        .show-info-btn .icon-info {
          display: block;
        }
      }

    }
  }

  &.list-type {

    @media (max-width: 600px) {

      .apps-list {
        gap: 12px;

        .show-info-btn {
          .icon-info {
            display: none;
          }
          
          .icon-expand {
            display: block;
          }
        }

        .app-item {
          width: 100%;
          padding-bottom: 0;
          background-color: var(--surface-secondary);
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          overflow: hidden;
          max-height: 64px;
          transition: max-height 300ms ease;

          &.is-shown {
            max-height: 300px;

            .show-info-btn {
              transform: rotate(180deg);
            }
          }

          .show-info-btn {
            bottom: unset;
            top: 0;
            right: 0;
            height: 64px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
          }

          .app-dl, .link-btn, .multi-tile-menu {
            z-index: 0;
          }

          .app-thumb {
            display: none;
          }

          .info-card {
            position: unset;
            padding: 0;

            .card-header {
              gap: 16px;
              padding: 8px 12px;
              background: var(--surface-tertiary);
              position: relative;
              z-index: 1;

              .app-icon {
                width: 48px;
                height: 48px;
              }
              h3 {
                width: 180px;
                bottom: -4px;
              }
              .type {
                opacity: 0;
              }
              .star-rating {
                opacity: 1;
              }
            }

            .card-body {
              padding: 16px 16px 72px;
              p {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .apps-list-actions {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
    display: none;

    @media (max-width: 600px) {
      display: flex;
    }
  }
}
