.desktop-notice {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 8px;
  background-color: var(--surface-info);
  font-size: 14px;
  line-height: 142.857%;
  color: var(--white);

  svg {
    width: 20px;
    height: 20px;

  }

  @media (max-width: 1024px) {
    display: flex;
  }
}
