.appstore-search {
  flex: 1;

  @media (max-width: 1024px) {
    flex: 0;
    order: 1;
  }

  .search-btn {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .appstore-search-inner {
    position: relative; 

    @media (max-width: 1024px) {
      display: none;
    }

    .search {
      position: relative; 
      height: 40px;
      flex: 1;
    }

    &:focus-within {

      .autocomplete {
        display: block;
      }
    }
  }

  input[type=search] {
    padding-left: 40px;
    padding-right: 48px;
    appearance: none;
    border: none;
    background-color: var(--surface-secondary);
    height: 40px;
    font-family: var(--font-montserrat);
    font-size: 16px;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration { display: none; }

    &:not(:placeholder-shown) {
      + .clear-search {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  .search-icon {
    width: 20px;
    height: 20px;
    color: var(--text-secondary);
    position: absolute;
    left: 12px;
    top: 0;
    bottom: 0 ;
    margin: auto;
    pointer-events: none;
  }

  .clear-search {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    cursor: auto;
    padding: 0;
    width: 24px;
    height: 24px;
    z-index: 10;

    svg {
      width: 24px;
      height: 24px;
      position: relative;
    }
  }

  .back-btn {
    display: none;
  }

  .autocomplete {
    position: absolute;
    left: 0;
    top: 41px;
    width: 100%;
    max-height: 246px;
    background-color: var(--surface-secondary);
    overflow-y: auto;
    display: none;

    @media (max-width: 1024px) {
      max-height: auto;
      top: 80px;
    }

    .autocomplete-list {

      a {
        font-family: var(--font-montserrat);
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 12px;

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        svg {
          width: 20px;
          height: 20px;
          flex: none;
          color: var(--text-quaternary);
        }

        &:hover, &:focus {
          background-color: var(--surface-hover-regular);
          color: inherit;
        }

        &.selected {
          background-color: var(--surface-hover-regular);
          color: inherit;
        }

      }
    }
  }

}

// show special style for mobile, only if its in the search page layout
 body:has(.search-page-layout) {
  .appstore-search {

    .appstore-search-inner {

      @media (max-width: 1024px) {
        background-color: var(--black);
        position: fixed;
        width: 100%;
        height: 80px;
        left: 0;
        top: 0;
        z-index: 100;
        display: flex;
        gap: 4px;
        padding: 20px 20px 0px 8px;
        transition: opacity 300ms ease;
        opacity: 1;

        .back-btn {
          display: block;
        }
      }
    }

    .search-btn {
      display: none;
    }

    .autocomplete {
      @media (min-width: 1024px) {
        display: none;
      }
    }
  }
}
