.creator-social {
  h4 {
    font-family: var(--font-lato);
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 16px;
  }

  .creator-social-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}
