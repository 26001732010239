.big-banner {
  padding: 0 20px;
  max-width: 1436px;
  margin: 56px auto;
  display: block;

  .mobile {
    display: none;
  }

  @media (max-width: 600px) {
    .desktop {
      display: none;
    }
    
    .mobile {
      display: block;
      width: 100%;
    }
  }
}

body:has(.app-page-layout) {
  .big-banner {
    max-width: 1162px;
  }
}
