body {
  &:has(.error-placeholder) {
    .site-footer .back-to-top {
      display: none
    }
  }
}

.error-placeholder {
  padding: 48px 20px 96px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    padding: 24px 20px;
  }

  .error-inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  h1 {
    font-size: 96px;
    text-align: center;
    line-height: 104px; /* 108.333% */
  }
}
