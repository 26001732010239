.browse-more-apps-section {
  margin: auto;
  max-width: 1112px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--surface-tertiary);
  position: relative;

  @media (max-width: 600px) {
    border-bottom: none;
    margin-bottom: 40px;
    padding-bottom: 0;
  }

  + .app-pairing-section {
    margin-top: 32px;
  }

  h2 {
    font-size: 24px;
    line-height: 116.667%;
    margin-bottom: 24px;
  }

  .browse-more-apps-list {
    display: flex;
    gap: 24px;

    @media (max-width: 600px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .app-item {
      a {
        display: block;

        h6 {
          font-family: var(--font-lato);
          font-size: 18px;
          line-height: 155.556%;
          padding: 12px 16px;
          background-color: var(--surface-secondary);
          text-transform: capitalize;
        }

        &:hover {
          color: inherit;
          img {
            filter: brightness(1.2);
          }

          h6 {
            background-color: var(--surface-tertiary);
          }
        }
      }
    }
  }

  .clean-btn {
    position: absolute;
    top: 10px;
    right: 0;

    @media (max-width: 600px) {
      top: 3px;
    }
  }
}
