
.carousel-slider-section {
  user-select: none;
  position: relative;

  @media (max-width: 1024px) {
    display: none;
  }

  .carousel-slider-wrapper {
    position: relative;

    .carousel-slider-next, .carousel-slider-prev {
      width: 40px;
      height: 40px;
      padding: 4px;
      background-color: var(--ow-red);
      color: var(--white);
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 10;
      display: flex;
      align-items: center;
      opacity: 0;
      transition: opacity 300ms ease;

      &:hover {
        background-color: var(--ow-red-hover);
      }

      &::after {
        display: none;
      }

      &:disabled {
        opacity: 0;
        pointer-events: none;
        cursor: auto;
      }
    }

    &:hover {
      .carousel-slider-next, .carousel-slider-prev {
        opacity: 1;

        &:disabled {
          opacity: 0.4;
        }
      }
    }

    .carousel-slider-next {
      right: 20px;
      transform: rotate(180deg)
    }

    .carousel-slider-prev {
      left: 20px;
    }
  }

  .carousel-list {

    li {
      padding: 57px 0;
      position: relative;

      @media (max-width: 600px) {
        padding: 14px 0 70px 0;
      }

      img {

        &.bg-image {
          position: absolute;
          top: -80px;
          left: 0;
          width: 100%;
          z-index: -1;

          @media (max-width: 1024px) {
            top: 0px;
          }

          + a {
            position: relative;
            z-index: 2;
          }
        }

        &.cta-image {
          cursor: pointer;
          display: block;
          margin: auto;
        }
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        height: 320px;
        width: 100%;
        left: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--black) 90%);
        pointer-events: none;
      }

    }
  }

  .carousel-slider-pagination {
    display: flex;
    gap: 24px;
    width: fit-content;
    margin: 0 auto 48px;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    &:empty {
      display: none;
    }

    span {
      background-color: var(--text-quaternary);
      border: 1px solid transparent;
      opacity: 1;
      width: 8px;
      height: 8px;
      border-radius: 50%;

      &.swiper-pagination-bullet-active {
        background-color: transparent;
        border: 1px solid var(--white);
        width: 10px;
        height: 10px;
      }
    }
  }

  .swiper-fade {
    &.swiper-free-mode {
      .swiper-slide {
        transition-timing-function: ease-out;
      }
    }
    .swiper-slide {
      pointer-events: none;
      transition-property: opacity;
      .swiper-slide {
        pointer-events: none;
      }
    }
    .swiper-slide-active {
      &,
      & .swiper-slide-active {
        pointer-events: auto;
      }
    }
  }

}
