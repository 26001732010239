.tags {
  margin: 24px 0 32px;

  @media (max-width: 800px) {
    margin: 16px 0;
  }

  .tag-group-title {
    font-family: var(--font-lato);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 155.556%;
    margin-bottom: 16px;
  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .tag-item {
      padding: 4px 12px;
   
      @media (max-width: 600px) {
        padding: 8px 8px;
      }
    }

    .tag-item,
    .tag-item-link {
      font-family: var(--font-montserrat);
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      height: 32px;
      color: var(--text-tertiary);

      a,
      button {
        color: var(--text-tertiary);
        padding: 4px 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        background-color: transparent;
        color: inherit;
        height: auto;
        line-height: 143%;
        height: 32px;
        text-transform: inherit;

        @media (max-width: 600px) {
          padding: 8px 8px;

          &:not(button) {
            pointer-events: none;
            background-color: var(--surface-secondary);
          }
        }
      }

      svg,
      img {
        width: 24px;
        height: 24px;
      }

    }

    .tag-item {
      background-color: var(--surface-secondary);
    }

    .tag-item-link {
      background-color: var(--surface-tertiary);

      a,
      button {
        color: var(--text-tertiary);

        &:hover {
          background-color: var(--surface-quaternary);
          color: var(--text-secondary);
        }
      }
    }
  }

  &.supported-games-tag-list {
   margin-bottom: 0;
  }

  &.features-tag-list {
    margin-bottom: 24px;
  }
}
