.breadcrumb {
  max-width: 1436px;
  margin: auto;
  padding: 24px 20px 32px;

  @media (max-width: 1024px) {
    padding: 0 20px 24px;
  }

  ol {
    list-style: none;
    display: flex;
    gap: 4px;

    li {
      display: flex;
      align-items: center;

      a {
        font-family: var(--font-montserrat);
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--text-quaternary);

        svg {
          width: 20px;
          height: 20px;
          transform: rotate(-90deg);
        }

        &:hover {
          color: var(--ow-red-hover);

          svg {
            color: var(--text-quaternary);
          }
        }

      }

      &:last-child {
        a {
          color: var(--text-secondary);
          pointer-events: none;
        }
      }
    }
  }
}
