.supported-games-modal {
  .show-supported-games-modal-button {
    font-family: var(--font-montserrat);
    height: 24px;
    background-color: var(--surface-tertiary);
    padding: 4px 8px;
    font-size: 12px;
    color: var(--text-tertiary);
    position: relative;
    top: -2px;
    text-transform: inherit;

    &:hover {
      background-color: var(--surface-quaternary);
      color: var(--text-secondary);
    }
  }

  .supported-games-modal-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(22, 22, 22, 0.79);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    opacity: 1;
    visibility: visible;
    transition: all 300ms ease;

    &:not(.modal-is-open) {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    .supported-games-modal-main {
      background-color: var(--surface-secondary);
      position: relative;
      width: 560px;
      height: 630px;

      @media (max-width: 800px) {
        width: 100%;
      }

      @media (max-width: 500px) {
        height: 520px;
      }

      .close-modal {
        position: absolute;
        right: 8px;
        top: 8px;

        @media (max-width: 500px) {
          right: 0;
          top: 0;
        }
      }

      iframe {
        width: 100%;
        height: 630px;
        border: none;
      }
    }

    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;

      @media (max-width: 500px) {
        pointer-events: none;
      }
    }
  }
}

//outside of scss nesting to support styles inside outplayed game list iframe
.supported-games-modal-content {
  .sg-header {
    padding: 24px;
    background-color: var(--surface-tertiary);
    display: flex;
    gap: 16px;
    align-items: flex-end;

    @media (max-width: 500px) {
      padding: 16px;
    }

    .app-thumb {
      width: 56px;

      @media (max-width: 500px) {
        width: 48px;
      }
    }

    .app-title {
      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 116.667%;
        margin-bottom: 4px;
        color: inherit;

        @media (max-width: 500px) {
          font-size: 20px;
        }
      }

      p {
        font-family: var(--font-montserrat);
        font-size: 14px;
        line-height: 140%;
        color: var(--text-quaternary);
      }
    }
  }

  .sg-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--surface-tertiary);
    padding: 12px 16px;
    text-align: center;

    p {
      color: var(--text-quaternary);
      font-size: 14px;
      line-height: 142.857%; 
    }
  }

  .sg-search {
    padding: 32px 32px 24px;

    @media (max-width: 500px) {
      padding: 16px;
    }
  }

  .sg-list-wrapper {
    overflow-y: auto;
    height: 370px;
    margin-right: 8px;
    padding: 0 16px 0 32px;
    position: relative;

    @media (max-width: 500px) {
      height: 286px;
      padding: 0 16px 0 16px;
    }

    .sg-full-list {
      li {
        font-size: 16px;
        color: var(--text-secondary);
        line-height: 150%;
        padding: 8px 0;
        display: flex;
        gap: 8px;
        align-items: center;

        .game-icon {
          width: 24px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--surface-tertiary);
        }

        &:first-child {
          padding-top: 0;
        }
      }
    }
  }

  .no-results-placeholder {
    background-color: var(--surface-secondary);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
