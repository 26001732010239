.app-pairing-section {
  margin: 0 auto;
  max-width: 1112px;
  position: relative;

  h2 {
    font-size: 24px;
    line-height: 116.667%;
    margin-bottom: 24px;
  }

  .app-pairing-list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    @media (max-width: 600px) {
      gap: 12px;
    }

    .app-pairing-item {
      flex: 1;
      min-width: 250px;

      a {
        padding: 12px;
        display: flex;
        align-items: center;
        height: 100%;
        column-gap: 12px;
        background-color: var(--surface-secondary);

        * {
          pointer-events: none;
        }

        &:hover {
          background-color: var(--surface-tertiary);
          color: inherit;
        }

        .asset {
          flex: none;

          img {
            width: 48px;
          }
        }

        .content {
          overflow: hidden;

          h6 {
            color: var(--white);
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p {
            font-family: var(--font-montserrat);
            color: var(--text-tertiary);
            font-weight: 500;
            font-size: 12px;
            line-height: 133.333%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

}
